<template>
    <div class="forbidden">
        <div class="text-wrapper">
            <div class="title" data-content="404">
                <span>403</span>
                <p class="mt-2">رفض الوصول</p>
            </div>
            <div class="subtitle">عفواً ، ليس لديك إذن للوصول إلى هذه الصفحة.</div>

            <v-btn color="primary" class="btn mt-8" large @click="back">الصفحة الرئيسية</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        },
        back() {
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
.forbidden {
    height: 100vh;
    background: #233142;
}

.text-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.title {
    font-weight: 700;
    color: #facf5a;
    span {
        font-size: 100px;
        font-family: cursive;
    }
    p {
        color: white;
    }
}

.subtitle {
    font-size: 40px;
    font-weight: 700;
    color: white;
}
</style>
